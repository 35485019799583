import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import NavbarComponent from "./NavbarComponent";
import LazyComponent from "./components/LazyComponent";
import { Helmet } from "react-helmet";

/**
 * @param {{page: string, title: string }} props
 */
const LazyPage = ({ page, title }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>{title} - SIEK</title>
            </Helmet>
            <LazyComponent location={`pages/${page}`} />
        </React.Fragment>
    );
};

function App() {
    return (
        <div className="bg-primary">
            <div className="d-block d-lg-none sticky-top w-100">
                <NavbarComponent />
            </div>
            <Container fluid className="bg-primary">
                <Row className="flex-column min-vh-100">
                    <Col xs="auto" className="d-none d-lg-flex">
                        <Header />
                    </Col>
                    <Col className="min-vh-75">
                        <Routes>
                            <Route path="*" element={<LazyPage page="NotFound" title="Página no encontrada"/>} />
                            <Route path="/" element={<LazyPage page="MainPage"title="Inicio" />} />
                            <Route path="/bio" element={<LazyPage page="Bio" title="Biografía"/>} />
                            <Route path="/cro" element={<LazyPage page="Cronologia" title="Cronología"/>} />
                            <Route path="/siek" element={<LazyPage page="SIEK" title="La sociedad"/>} />
                            <Route path="/biblio" element={<LazyPage page="Biblio" title="Bibliografía"/>} />
                            <Route path="/boletin" element={<LazyPage page="Boletin" title="Boletín"/>} />
                            <Route path="/obras" element={<LazyPage page="Obras" title="Obras"/>} />
                            <Route path="/fotos" element={<LazyPage page="Fotos" title="Fotografías"/>} />
                        </Routes>
                    </Col>
                    <Col xs="auto">
                        <Footer />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;
