import React from "react";
import NavbarComponent from "./NavbarComponent";
import { Container, Row, Col } from "react-bootstrap";

export default function Header() {
    return (
        <Container className="py-3">
            <Row className="pb-3 align-items-center text-white d-none d-lg-flex">
                <Col xs="auto"><img height={120} src="/images/logo_lat.png" alt="SIEK logo"/></Col>
                <Col>
                    <h2 className="h2">Søren Kierkegaard</h2>
                    <h1 className="h3">Sociedad Iberoamericana de Estudios Kierkegaardianos</h1>
                </Col>
            </Row>
            <NavbarComponent />
        </Container>
    );
}
