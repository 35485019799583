import React, { useMemo } from "react";
import { Container } from "react-bootstrap";

export default function Footer() {
    const currentYear = useMemo(() => {
        const today = new Date();
        return today.getFullYear();
    }, []);
    return (
        <Container className="text-center py-3 text-white-50">
            <div>
                &copy; 2014-{currentYear} Sociedad Iberoamericana de Estudios Kierkegaardianos | Todos los
                derechos reservados
            </div>
            <div>
                <span className="me-0 me-md-3">Email: luis.guerrero@siek.mx</span>
                <div className="d-block d-md-none"/>
                <span className="ms-0 ms-md-3">Editor: <b>Dr. Luis Guerrero M.</b></span>
            </div>
             
        </Container>
    );
}
