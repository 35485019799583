import React, { useCallback, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import links from "./assets/header.json";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function NavbarComponent() {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const onToggle = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <React.Fragment>
            <Navbar
                expand="lg"
                bg="transparent"
                className="fw-bold w-100 pt-0 py-lg-0"
                onToggle={onToggle}
                expanded={expanded}
                collapseOnSelect
            >
                <div className="w-100 bg-light py-2 d-lg-none d-flex flex-row align-items-center shadow">
                    <div className="px-3">
                        <Link to="/">
                            <img src="/images/logo_lat.png" alt="SIEK" width={50} />
                        </Link>
                    </div>
                    <div className="flex-grow-1 h5 my-0 py-0">
                        <span className="d-block d-md-none">
                            <Link to="/" className="text-decoration-none text-black">
                                SIEK
                            </Link>
                        </span>
                        <span className="d-none d-md-block">
                            <Link to="/" className="text-decoration-none text-black">
                                Sociedad Iberoamericana de Estudios Kierkegaardianos
                            </Link>
                        </span>
                    </div>
                    <div>
                        <Navbar.Toggle className="ms-auto me-3 border-0">
                            <i className={`bi bi-list ${expanded ? 'text-info' : ''}`} style={{fontSize: "xx-large"}}/>
                        </Navbar.Toggle>
                    </div>
                </div>
                <div className="w-100 px-5 px-lg-0">
                    <Navbar.Collapse className="border-top border-lg-0 border-3 border-info rounded-0 rounded-bottom-4 rounded-lg-pill fw-bold w-100 bg-light py-lg-2 text-end px-3 pb-2 shadow-lg">
                        <Nav className="mx-auto">
                            {links.map((link) => (
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        if (link.url !== "/revista") {
                                            navigate(link.url);
                                        } else {
                                            window.location.replace(link.url);
                                        }
                                    }}
                                    className="px-lg-3 border-bottom border-1 border-lg-0"
                                    key={link.url}
                                    active={location.pathname === link.url}
                                >
                                    <div className="px-lg-0 px-3">{link.name}</div>
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {expanded && (
                <div className="w-100 vh-100 opacity-25 bg-black top-0 position-absolute z-n1" onClick={onToggle} />
            )}
        </React.Fragment>
    );
}
