import React, { lazy, useMemo, Suspense } from "react";
import { Spinner } from "react-bootstrap";

const Fallback = () => (
    <div className="text-center pt-5">
        <Spinner />
    </div>
);

export default function LazyComponent({ location }) {
    const Component = useMemo(() => {
        return lazy(() => import(`../${location}`));
    }, [location]);
    return (
        <Suspense fallback={<Fallback />}>
            <Component />
        </Suspense>
    );
}
